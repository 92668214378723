import { toast } from "react-toastify";
import {
	NewAccountSteps,
	AdditionalAccountSteps,
	BusinessCategory,
	Products,
	AccountReactivationSteps,
	RoutesEnum,
	BusinessRegistrationSteps,
	TruthyEnum,
} from "../enums";
import moment from "moment";
import { businessTypes, states } from "../constants";
import config from "../../config/config";
const CryptoJS = require("crypto-js");

export function formatArray(options: any[], value = "", label = "") {
	if (!options) return [];

	if (value && label)
		return options.map((opt) => ({
			label: opt[label],
			value: opt[value],
		}));

	return options.map((opt) => ({ label: opt, value: opt }));
}

export async function downloadReference() {
	fetch("./reference.pdf").then((response) => {
		response.blob().then((blob) => {
			// Creating new object of PDF file
			const fileURL = window.URL.createObjectURL(blob);
			// Setting various property values
			let alink = document.createElement("a");
			alink.href = fileURL;
			alink.download = "Reference_Form.pdf";
			alink.click();
		});
	});
}

export const notifySuccess = (msg: string) => {
	toast.success(msg);
};

export const notifyInfo = (msg: string) => {
	toast.info(msg);
};

export const notifyError = (msg: string) => {
	toast.error(msg);
};

export const isFileSizeValid = (size: number) => {
	return size / 1024 ** 2 <= 5;
};

export const isFileTypeValid = (type: string, fileTypes = ["pdf", "jpg", "jpeg", "png"]) => {
	const fileType = type.split(".").pop()?.toLowerCase();
	if (!fileType) return undefined;
	return fileTypes.includes(fileType);
};

export const updateItemInArrayOfObjects = (array: any[], index: number, objectKey: string, value: any) => {
	const objectToUpdate = array[index];
	array[index] = { ...objectToUpdate, [objectKey]: value };
	return array;
};

export const updateItemInArrayOfObjectsX = (array: any[], index: number, newObject: any) => {
	const objectToUpdate = array[index];
	array[index] = { ...objectToUpdate, ...newObject };
	return array;
};

export const saveDataLocally = (
	key: string,
	value: any,
	storageKey: RoutesEnum | string = RoutesEnum.accountOpening
) => {
	let data = localStorage.getItem(storageKey);
	if (data) {
		const d = JSON.parse(data) as any;
		localStorage.setItem(storageKey, JSON.stringify({ ...d, [key]: value }));
	} else {
		localStorage.setItem(storageKey, JSON.stringify({ [key]: value }));
	}
};

export const getLocalData = (key: string, storageKey: RoutesEnum | string = RoutesEnum.accountOpening) => {
	let data = localStorage.getItem(storageKey) as any;
	if (!data) return;
	const d = JSON.parse(data);
	return d[key];
};

export const removeLocalData = (storageKey: RoutesEnum | string = RoutesEnum.accountOpening) =>
	localStorage.removeItem(storageKey);

export function isEmpty(value: any) {
	return (
		value === null ||
		value === undefined ||
		(typeof value === "object" && Object.keys(value).length === 0) ||
		(typeof value === "string" && value.trim().length === 0)
	);
}

export function removeEmptyKeys(data: any) {
	const obj: any = {};
	if (typeof data === "object") {
		for (const keys of Object.keys(data)) {
			if (!isEmpty(data[keys])) obj[keys] = data[keys];
		}
		return obj;
	}
	return data;
}

export const findArrayItem = (list: any[], key: string, value: any, objectKey?: any) => {
	if (!list || !value) return "";
	const data = list.find((l) => l?.[key] === value);
	if (!data) return;
	if (objectKey && data[objectKey]) return data[objectKey];
	return data;
};

export const handleErrorMessage = (description?: string) => {
	notifyError(description || "Something went wrong, please try again later.");
};

export const formatDate = (date: string) => {
	const d = date.split("-");
	return `${d[2]}-${d[1]}-${d[0]}`;
};

export const capitalize = (words: string) => {
	if (!words) return "";
	const w = words.trim().toLowerCase().split(" ");
	if (w.length === 1) return w[0].charAt(0).toUpperCase() + w[0].slice(1);

	let capsArray = "";
	w.forEach((word) => {
		if (word[0]) {
			capsArray += word[0].toUpperCase() + word.slice(1);
		}
	});
	return capsArray;
};

export const getBusinessCategoryID = (productType: string, category: string) => {
	if (productType === Products.FcmbBusinessAccount && category === BusinessCategory.LimitedLiabilityCompany) {
		return "OD244";
		// scheme code
	} else if (
		productType === Products.FcmbBusinessAccount &&
		(category === BusinessCategory.SoleProprietorship || category === BusinessCategory.NGOAssociationCooperative)
	) {
		return "OD231";
	} else if (
		productType === Products.CorporateCurrentAccount &&
		category === BusinessCategory.LimitedLiabilityCompany
	) {
		return "OD206";
	} else if (
		productType === Products.CorporateCurrentAccount &&
		(category === BusinessCategory.SoleProprietorship || category === BusinessCategory.NGOAssociationCooperative)
	) {
		return "OD205";
	}
};

export const getDocumentFieldValue = (document: string) => {
	switch (document) {
		case "CTC of Form CO7":
			return "formOfCO7";
		case "CTC of Form CO2":
			return "formOfCAC23";
		case "CTC of Memo and Articles of Association":
			return "memorandumAndArticleOfAssociation";
		case "SIGNATURE CARDS":
			return "signatureOfSignatories";
		case "Executed and sealed Board Resolution":
			return "boardResolution";
		case "Reference":
			return "firstReferenceForCompanies";
		case "Reference 2":
			return "secondReferenceForCompanies";
		case "Scuml":
			return "scumlCertificate";
		case "Form of Application for Registration of Business Name":
			return "registrationFormsSentElectronicallyByCAC";
		case "Constitutions,Rules,Regulations and By-laws":
			return "constitutionRulesAndRegulations";
		case "Society/Club/Association Resolution to Open the account":
			return "societyClubAssociationCooperativeResolutionToOpenAccount";
		case "Certificate of Incorporation7":
			return "certificateOfIncorporation";
		default:
			return "others";
	}
};

// move to helpers when resolved
export const convertBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);

		fileReader.onload = () => {
			resolve(fileReader.result);
		};

		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};

export const isTaxCacRequired = (value: string) =>
	value === BusinessCategory.SoleProprietorship || value === BusinessCategory.LimitedLiabilityCompany;

export const isBusinesssNGO = (value: string) => {
	return value === BusinessCategory.NGOAssociationCooperative;
};

export const isCompanyNameValid = (rcNumber: string, txRCNumber: string) => {
	return rcNumber.toLowerCase()?.trim() === txRCNumber.toLowerCase()?.trim();
};

export const formatAccountOpeningPayload = (values: any) => {
	return {
		corporateName: values?.corporateName,
		dateOfIncorporation: values?.dateOfIncorporation,
		phoneNumber: values.phoneNumbers[0]?.number.toString(),
		email: values?.email,
		currencyCode: "NGN",
		registrationNumber: values?.rcNumber,
		averageAnnualincome: values?.averageAnnualIncome,
		sector: values?.sector,
		subsector: values?.subSector,
		sourceOfFunds: values?.sourceOfFunds,
		taxid: values?.tin,
		segment: "SME",
		subsegment: `${values?.subSegment[0]}${values?.branch}${values?.subSegment.slice(-1)}`,
		businessType: businessTypes[values.businessCategory?.trim()],
		legalentityType: values?.legalEntity,
		region: values?.region,
		keycontactPersonname: values?.keyContactPersonName,
		primaryServiceCenter: values?.branch,
		schemeCode: values?.schemeCode,
		branchCode: values?.branch,
		brokerCode: values?.referralCode ? values?.referralCode : "1072",
		corporateAddressData: [
			{
				addressLine1: `${values?.houseNo}, ${values?.streetName} ${findArrayItem(
					states,
					"stateCode",
					values?.state,
					"state"
				)}`,
				addressLine2: `${values?.houseNo}, ${values?.streetName} ${findArrayItem(
					states,
					"stateCode",
					values?.state,
					"state"
				)}`,
				addressLine3: `${findArrayItem(states, "stateCode", values?.state, "state")}`,
				city: values?.city,
				houseNo: values?.houseNo,
				state: values.state,
				country: "NG",
			},
		],
		certificateOfIncorporation: {
			referenceNumber: values?.rcNumber,
			docCode: "CORP",
			docTypeCode: "CERTI",
			placeOfIssue: values?.city,
			documentIssueDate: `${moment(new Date(values?.dateOfIncorporation)).format("YYYY-MM-DD")}T07:30:51.758Z`,
			countryOfIssue: "NG",
		},
		isAuthenticated: false,
		outstandingDocuments: ["null"],
		"accountOfficerCode": values?.accountOfficerCode,
		"sbuCode": `${values?.subSegment[0]}${values?.branch}${values?.subSegment.slice(-1)}`,
		documentCategoryCode: values?.documentCategoryCode
	};
};

export const getPlaceOfIssueCode = (placeOfIssue: string) => {
	const location = findArrayItem(states, "state", placeOfIssue.toLowerCase(), "stateCode");
	return location ? location : "LG";
};

function formatNumber(num: number) {
	return num < 10 ? '0' + num : num.toString();
}

export const formatGenerateMemberPayload = (values: any) => {
	return {
		bvn: values?.bvn,
		nin: values?.idNumber,
		corporate_Account: "",
		relationshipType: "R",
		dateOfBirth: `${moment(values?.dob).format("YYYY-MM-DD")}T23:29:27.375Z`,
		gender: values?.gender,
		salutation: values?.title,
		firstName: values?.firstName?.trim(),
		lastName: values?.lastName?.trim(),
		preferredName: values?.firstName?.trim(),
		primarySolID: values?.branch,
		email: values?.email,
		schemeCode: values?.schemeCode,
		phoneNumber: values?.phoneNumber,
		phoneCountryCode: "234",
		phoneEmailType: "phone",
		contactAddressDetails: {
			country: values?.countryResidence,
			zip: "234",
			state: values?.state,
			city: values?.lga,
			houseNo: values?.houseNo,
			streetName: values?.streetName,
		},
		contactPhoneEmailDataList: [
			// {
			// 	"email": values?.email,
			// 	"phoneNo": values?.phone_number,
			// 	"phoneNoCountryCode": "234",
			// 	"phoneEmailType": "phone"
			// }
		],
		contactRelatedDtlsContactDemographicData: {
			employmentStatus: values?.employmentStatus,
			maritalStatus: values?.maritalStatus,
		},
		contactDocumentData: {
			countryOfIssue: "234",
			documentCode: values?.documentCode,
			placeOfIssue: "NG",
			document_ID_No: values?.idNumber,
			docIssueDate: values?.issuedDate
				? `${formatDate(values?.issuedDate)}T23:29:27.375Z`
				: "2012-06-19T23:29:27.375Z",
			referenceNum: generateUniqueId(15),
			docExpiryDate: values?.expiryDate
				? `${formatDate(values?.expiryDate)}T23:29:27.375Z`
				: "2029-06-19T23:29:27.375Z",
			docTypeCode: "PRIM",
		},
	};
};

export const getGender = (g: any) => {
	if (!g) return;
	if (g.toLowerCase() === "male" || g.toLowerCase() === "m") return { label: "Male", value: "M" };
	if (g.toLowerCase() === "female" || g.toLowerCase() === "f") return { label: "Female", value: "F" };
};

export const isStateCityNigerian = (countryCode: string) => countryCode === "NG";

export const isCameraDevice = "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices;

export function generateUniqueId(length = 40) {
	const alphanumericChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
		result += alphanumericChars.charAt(randomIndex);
	}

	return result;
}

export function validateReferenceAccount(schemeCode: string) {
	return ["OD205", "OD206", "OD231", "OD244", "OD219", "OD234", "OD245", "OD233"].includes(schemeCode);
}

export const formatBvnDate = (date: string) => {
	const dateArray = date.split("-");
	return new Date(`${dateArray[0]}, ${dateArray[1]} ${dateArray[2]}`);
};

export const formatAccountOpeningSaveAndContinue = (values: any, currentStep: string) => {
	switch (currentStep) {
		case NewAccountSteps.companyInfo:
			return {
				currentStep,
				progress: 10,
				companyInfo: {
					businessCategory: values?.businessCategory?.value,
					companyEmail: values?.companyEmail,
					referralCode: values?.referralCode,
					accountOfficerCode: values?.accountOfficerCode,
				},
			};
		case NewAccountSteps.productInfo:
			return {
				currentStep,
				progress: 20,
				productInfo: {
					productType: values?.productType?.label ?? "",
					id: values?.productType?.value ?? "",
					schemeCode: values?.schemeCode ?? "",
				},
			};
		case NewAccountSteps.companyDetails:
			return {
				currentStep,
				progress: 30,
				companyDetails: {
					state: values?.state?.value ?? null,
					ownershipStructure: values?.ownershipStructure?.value ?? null,
					averageAnnualIncome: values?.averageAnnualIncome?.value ?? null,
					city: values?.city?.value ?? null,
					businessDescription: values?.businessDescription ?? null,
					employeesNumber: values?.employeesNumber ? values?.employeesNumber : 0,
					incorporationCert: values?.incorporationCert ?? null,
					streetName: values?.streetName ?? null,
					isTaxCacRequired: true,
					phoneNumbers:
						values?.phoneNumbers.map((p: any) => ({
							countryCode: p?.countryCode?.value,
							number: p?.number?.toString(),
						})) ?? [],
					rcNumber: values?.rcNumber ?? null,
					tin: values?.tin ?? null,
					isRnTaxValid: values?.isRnTaxValid ?? false,
					corporateName: values?.corporateName ?? null,
					registeredBusinessAddress: values?.registeredBusinessAddress ?? null,
					dateOfIncorporation: values?.dateOfIncorporation ?? null,
					directorsFromCAC:  values?.directorsFromCAC || [],
					keyPersonnelFromCAC: values?.keyPersonnelFromCAC || [],
					currencyCode: "NGN",
					countryCode: "NGN",
					sector: values?.sector?.value ?? null,
					subSector: values?.subSector?.value ?? null,
					segment: "SME",
					subSegment: values?.subSegment?.value ?? null,
					legalEntity: values?.legalEntity?.value ?? null,
					region: values?.region ?? null,
					sourceOfFunds: values?.sourceOfFunds ?? null,
					branch: values?.branch?.value ?? null,
					branchLocation: values?.branchLocation?.value ?? null,
					placeOfIssue: values?.placeOfIssue ?? null,
					houseNo: values?.houseNo ?? null,
					keyContactPersonName: values?.keyContactPersonName ?? null,
					numberOfDirectors: values?.numberOfDirectors ? values?.numberOfDirectors : null,
					sbuCode: `${values?.subSegment?.value[0]}${values?.branch
						?.value}${values?.subSegment?.value?.slice(-1)}`,
					isCacVerified: values?.isCacVerified,
					isTinVerified: values?.isTinVerified,
				},
			};
		case NewAccountSteps.directorsDetails:
			return {
				currentStep,
				progress: 40,
				directorsDetails: values?.directors?.map((d: any) => ({
					bvn: d?.bvn ?? null,
					idType: d?.idType?.value?.toString() ?? null,
					firstName: d?.firstName ?? null,
					lastName: d?.lastName ?? null,
					dob: d?.dob ?? null,
					idNumber: d?.idNumber ?? null,
					title: d?.title?.value ?? null,
					occupation: d?.occupation?.value ?? null,
					companyPosition: d?.companyPosition?.value ?? null,
					gender: d?.gender?.value ?? null,
					countryResidence: d?.countryResidence?.value ?? null,
					nationality: d?.nationality?.value ?? null,
					state: d?.state?.value ?? null,
					lga: d?.lga?.value ?? null,
					email: d?.email ?? null,
					idCard: d?.idCard ?? null,
					phoneNumber: d?.phoneNumber ?? null,
					isNigerian: d?.isNigerian,
					maritalStatus: d?.maritalStatus?.value ?? null,
					employmentStatus: d?.employmentStatus?.value ?? null,
					houseNo: d?.houseNo ?? null,
					streetName: d?.streetName ?? null,
					zip: d?.countryResidence?.value ?? null,
					documentCode: d?.documentCode ?? null,
					isSignatory: d?.isSignatory,
					issuedDate: d?.issuedDate,
					expiryDate: d?.expiryDate,
					isUsCitizen: d?.isUsCitizen,
				})),
			};
		case NewAccountSteps.signatoriesDetails:
			return {
				currentStep,
				progress: 50,
				signatoriesDetails: values?.signatories?.map((s: any) => ({
					bvn: s?.bvn ?? null,
					idType: s?.idType?.value?.toString() ?? null,
					firstName: s?.firstName ?? null,
					lastName: s?.lastName ?? null,
					dob: s?.dob ?? null,
					idNumber: s?.idNumber ?? null,
					title: s?.title?.value ?? null,
					occupation: s?.occupation?.value ?? null,
					companyPosition: s?.companyPosition?.value ?? null,
					gender: s?.gender?.value ?? null,
					countryResidence: s?.countryResidence?.value ?? null,
					nationality: s?.nationality?.value ?? null,
					state: s?.state?.value ?? null,
					lga: s?.lga?.value ?? null,
					email: s?.email ?? null,
					idCard: s?.idCard ?? null,
					phoneNumber: s?.phoneNumber ?? null,
					isNigerian: s?.isNigerian,
					maritalStatus: s?.maritalStatus?.value ?? null,
					employmentStatus: s?.employmentStatus?.value ?? null,
					houseNo: s?.houseNo ?? null,
					streetName: s?.streetName ?? null,
					zip: s?.countryCode?.value ?? null,
					signature: s?.signature ?? null,
					passport: s?.passport ?? null,
					documentCode: s?.documentCode ?? null,
					issuedDate: s?.issuedDate,
					expiryDate: s?.expiryDate,
				})),
			};
		case NewAccountSteps.bankingServices:
			return {
				currentStep,
				progress: 60,
				bankingServices: {
					serviceRequests: values?.serviceRequests?.map((request: any) => request?.label) ?? [],
					businessRequests: values?.businessRequests?.map((request: any) => request?.label) ?? [],
					termsAndConditions: values?.termsAndConditions,
					emailIndemnity: values?.emailIndemnity,
					legalSearch: values?.legalSearch,
				},
			};
		case NewAccountSteps.accountMandate:
			return {
				currentStep,
				progress: 70,
				accountMandate: {
					authorizationRule: values?.authorizationRule?.value ?? null,
					other: values?.other ?? null,
					signatories: values?.signatories?.map((s: any) => ({
						...s,
						lowerLimit: s?.lowerLimit ? parseInt(s?.lowerLimit) : 0,
						upperLimit: s?.upperLimit ? parseInt(s?.upperLimit) : 0,
					})),
					confirmationRequired: values?.confirmationRequired,
					minConfirmationAmount: values?.confirmationLimit ? values?.confirmationLimit : 0,
				},
			};

		case NewAccountSteps.accountMandateConfirmation:
			return {
				currentStep,
				progress: 70,
				accountMandate: {
					authorizationRule: values?.authorizationRule,
					other: values?.other,
					signatories: values?.signatories,
					confirmationRequired: values?.confirmationRequired?.value ?? false,
					minConfirmationAmount: values?.confirmationLimit ? parseInt(values?.confirmationLimit) : 0,
				},
			};
		case NewAccountSteps.referenceDetails:
			return {
				currentStep,
				progress: 80,
				referenceDetails: {
					accountName: values?.accountName ?? null,
					accountNumber: values?.accountNumber ? values?.accountNumber?.toString() : null,
					bank: values?.bank?.value ?? null,
					refereeEmail: values?.refereeEmail ?? null,
					sendViaEmail: values?.sendViaEmail ?? null,
				},
			};
		case NewAccountSteps.documents:
			return {
				currentStep,
				progress: 90,
				documents: {
					cifId: values?.cifId ?? 0,
					accountNumber: values?.accountNumber ?? null,
					accountType: values?.accountType ?? null,
					accountCategory: values?.accountCategory ?? null,
					schemeCode: values?.schemeCode ?? null,
					deferralInPlace: values?.deferralInPlace ?? null,
					deferralApprover: values?.deferralApprover ?? null,
					certificateOfIncorporation: values?.certificateOfIncorporation ?? null,
					signatureOfSignatories: values?.signatureOfSignatories ?? null,
					memorandumAndArticleOfAssociation: values?.memorandumAndArticleOfAssociation ?? null,
					formOfCO7: values?.formOfCO7 ?? null,
					formOfCAC23: values?.formOfCAC23 ?? null,
					boardResolution: values?.boardResolution ?? null,
					instructionToTheBankToOpenAccount: values?.instructionToTheBankToOpenAccount ?? null,
					registrationFormsSentElectronicallyByCAC:
						values?.registrationFormsSentElectronicallyByCAC ?? null,
					printedBVNPageWithPixOfTheOwner: values?.printedBVNPageWithPixOfTheOwner ?? null,
					certificateOfRegistration: values?.certificateOfRegistration ?? null,
					constitutionRulesAndRegulations: values?.constitutionRulesAndRegulations ?? null,
					societyClubAssociationCooperativeResolutionToOpenAccount:
						values?.societyClubAssociationCooperativeResolutionToOpenAccount ?? null,
					firstReferenceForCompanies: values?.firstReferenceForCompanies ?? null,
					secondReferenceForCompanies: values?.secondReferenceForCompanies ?? null,
					scumlCertificate: values?.scumlCertificate ?? null,
					nipcCertificate: values?.nipcCertificate ?? null,
					others: values?.others ?? null,
					letterOfAppointmentAsBankersForEnterprise:
						values?.letterOfAppointmentAsBankersForEnterprise ?? null,
					formOfApplicationForRegistrationOfBusinessName:
						values?.formOfApplicationForRegistrationOfBusinessName ?? null,
					legalSearchReport: values?.legalSearchReport ?? null,
					addressVerification: values?.addressVerification ?? null,
					deedOfAppointmentAsTrustee: values?.deedOfAppointmentAsTrustee ?? null,
					letterOfIndemnity: values?.letterOfIndemnity ?? null,
					letterOfReferenceFromTheMinistryOfExternalAffairs:
						values?.letterOfReferenceFromTheMinistryOfExternalAffairs ?? null,
					offcialDocumentFromHomeCountry: values?.offcialDocumentFromHomeCountry ?? null,
					letterOfCredenceOfTheAmbassadorFromTheHomeCountry:
						values?.letterOfCredenceOfTheAmbassadorFromTheHomeCountry ?? null,
					approvalFromTheMinistryOfEducation: values?.approvalFromTheMinistryOfEducation ?? null,
					recentUtilityBill: values?.recentUtilityBill ?? null,
					certificateOfComplianceWithNEPB: values?.certificateOfComplianceWithNEPB ?? null,
					residencePermitCardExpatriatesSignatories:
						values?.residencePermitCardExpatriatesSignatories ?? null,
					passportPhotographsOfAllSignatories: values?.passportPhotographsOfAllSignatories ?? null,
					completedAccountPackage: values?.completedAccountPackage ?? null,
					taxIdentificationNumber: values?.taxIdentificationNumber ?? null,
					validIdOfAllSignatories: values?.validIdOfAllSignatories ?? null,
					validIdOfDirectors: values?.validIdOfDirectors ?? null,
					bvnOfSignatories: values?.bvnOfSignatories ?? null,
					bvnOfDirectors: values?.bvnOfDirectors ?? null,
					kycForm: values?.kycForm ?? null,
				},
			};
	}
};

export const formatMultiSelectValue = (label: string, value: string) => {
	if (!label || !value) return { label: "", value: "" };
	return { label, value };
};

export const getMultiSelectValue = (arrayValues: any[], key: string, value: any, label: string) => {
	const arrayItem = findArrayItem(arrayValues, key, value);
	if (!arrayItem) return "";
	return { label: arrayItem[label.trim()], value };
};

export const checkNullableValue = (val: any) => (val ? val : "");

export const accountOpeningSaveMsg = (type: "timeout" | "save", task = "Account Opening") => {
	const msg = type === "timeout" ? "Timeout!!\nProgress Saved Successfully" : `${task} Progress Saved Successfully`;
	return msg;
};

export const formatAdditionalAccountSaveAndContinue = (values: any, currentStep: string) => {
	switch (currentStep) {
		case AdditionalAccountSteps.accountInfo:
			return {
				currentStep,
				progress: 20,
				outstandingDocuments: [],
				accountInfo: {
					businessCategory: values?.businessCategory?.value,
					companyEmail: values?.companyEmail ?? "",
					referralCode: values?.referralCode,
					accountNumber: values?.accountNumber,
				},
			};

		case AdditionalAccountSteps.documents:
			return {
				currentStep,
				progress: 40,
				[currentStep]: {
					cifId: values?.cifId ?? 0,
					accountNumber: values?.accountNumber ?? null,
					accountType: values?.accountType ?? null,
					accountCategory: values?.accountCategory ?? null,
					schemeCode: values?.schemeCode ?? null,
					deferralInPlace: values?.deferralInPlace ?? null,
					deferralApprover: values?.deferralApprover ?? null,
					certificateOfIncorporation: values?.certificateOfIncorporation ?? null,
					signatureOfSignatories: values?.signatureOfSignatories ?? null,
					memorandumAndArticleOfAssociation: values?.memorandumAndArticleOfAssociation ?? null,
					formOfCO7: values?.formOfCO7 ?? null,
					formOfCAC23: values?.formOfCAC23 ?? null,
					boardResolution: values?.boardResolution ?? null,
					instructionToTheBankToOpenAccount: values?.instructionToTheBankToOpenAccount ?? null,
					registrationFormsSentElectronicallyByCAC:
						values?.registrationFormsSentElectronicallyByCAC ?? null,
					printedBVNPageWithPixOfTheOwner: values?.printedBVNPageWithPixOfTheOwner ?? null,
					certificateOfRegistration: values?.certificateOfRegistration ?? null,
					constitutionRulesAndRegulations: values?.constitutionRulesAndRegulations ?? null,
					societyClubAssociationCooperativeResolutionToOpenAccount:
						values?.societyClubAssociationCooperativeResolutionToOpenAccount ?? null,
					firstReferenceForCompanies: values?.firstReferenceForCompanies ?? null,
					secondReferenceForCompanies: values?.secondReferenceForCompanies ?? null,
					scumlCertificate: values?.scumlCertificate ?? null,
					nipcCertificate: values?.nipcCertificate ?? null,
					others: values?.others ?? null,
					letterOfAppointmentAsBankersForEnterprise:
						values?.letterOfAppointmentAsBankersForEnterprise ?? null,
					formOfApplicationForRegistrationOfBusinessName:
						values?.formOfApplicationForRegistrationOfBusinessName ?? null,
					legalSearchReport: values?.legalSearchReport ?? null,
					addressVerification: values?.addressVerification ?? null,
					deedOfAppointmentAsTrustee: values?.deedOfAppointmentAsTrustee ?? null,
					letterOfIndemnity: values?.letterOfIndemnity ?? null,
					letterOfReferenceFromTheMinistryOfExternalAffairs:
						values?.letterOfReferenceFromTheMinistryOfExternalAffairs ?? null,
					offcialDocumentFromHomeCountry: values?.offcialDocumentFromHomeCountry ?? null,
					letterOfCredenceOfTheAmbassadorFromTheHomeCountry:
						values?.letterOfCredenceOfTheAmbassadorFromTheHomeCountry ?? null,
					approvalFromTheMinistryOfEducation: values?.approvalFromTheMinistryOfEducation ?? null,
					recentUtilityBill: values?.recentUtilityBill ?? null,
					certificateOfComplianceWithNEPB: values?.certificateOfComplianceWithNEPB ?? null,
					residencePermitCardExpatriatesSignatories:
						values?.residencePermitCardExpatriatesSignatories ?? null,
					passportPhotographsOfAllSignatories: values?.passportPhotographsOfAllSignatories ?? null,
					completedAccountPackage: values?.completedAccountPackage ?? null,
					taxIdentificationNumber: values?.taxIdentificationNumber ?? null,
					validIdOfAllSignatories: values?.validIdOfAllSignatories ?? null,
					validIdOfDirectors: values?.validIdOfDirectors ?? null,
					bvnOfSignatories: values?.bvnOfSignatories ?? null,
					bvnOfDirectors: values?.bvnOfDirectors ?? null,
					kycForm: values?.kycForm ?? null,
				},
			};
		case AdditionalAccountSteps.productInfo:
			return {
				currentStep,
				progress: 60,
				[currentStep]: {
					productType: values?.productType?.label ?? "",
					id: values?.productType?.value ?? "",
					schemeCode: values?.schemeCode ?? "",
					currencyType: values?.currencyType?.value ?? "",
				},
			};

		case AdditionalAccountSteps.bankingServices:
			return {
				currentStep,
				progress: 80,
				bankingServices: {
					serviceRequests: values?.serviceRequests?.map((request: any) => request?.label) ?? [],
					businessRequests: values?.businessRequests?.map((request: any) => request?.label) ?? [],
					termsAndConditions: values?.termsAndConditions,
					emailIndemnity: values?.emailIndemnity,
					legalSearch: values?.legalSearch,
				},
			};
	}
};

export const formatAccountReactivationSaveAndContinue = (values: any, currentStep: string) => {
	switch (currentStep) {
		case AccountReactivationSteps.accountInfo:
			return {
				currentStep,
				progress: 33,
				outstandingDocuments: [],
				accountInfo: {
					businessCategory: values?.businessCategory?.value,
					companyEmail: values?.companyEmail,
					referralCode: values?.referralCode,
					accountNumber: values?.accountNumber,
				},
			};

		case AccountReactivationSteps.documents:
			return {
				currentStep,
				progress: 66,
				[currentStep]: {
					cifId: values?.cifId ?? 0,
					accountNumber: values?.accountNumber ?? null,
					accountType: values?.accountType ?? null,
					accountCategory: values?.accountCategory ?? null,
					schemeCode: values?.schemeCode ?? null,
					deferralInPlace: values?.deferralInPlace ?? null,
					deferralApprover: values?.deferralApprover ?? null,
					certificateOfIncorporation: values?.certificateOfIncorporation ?? null,
					signatureOfSignatories: values?.signatureOfSignatories ?? null,
					memorandumAndArticleOfAssociation: values?.memorandumAndArticleOfAssociation ?? null,
					formOfCO7: values?.formOfCO7 ?? null,
					formOfCAC23: values?.formOfCAC23 ?? null,
					boardResolution: values?.boardResolution ?? null,
					instructionToTheBankToOpenAccount: values?.instructionToTheBankToOpenAccount ?? null,
					registrationFormsSentElectronicallyByCAC:
						values?.registrationFormsSentElectronicallyByCAC ?? null,
					printedBVNPageWithPixOfTheOwner: values?.printedBVNPageWithPixOfTheOwner ?? null,
					certificateOfRegistration: values?.certificateOfRegistration ?? null,
					constitutionRulesAndRegulations: values?.constitutionRulesAndRegulations ?? null,
					societyClubAssociationCooperativeResolutionToOpenAccount:
						values?.societyClubAssociationCooperativeResolutionToOpenAccount ?? null,
					firstReferenceForCompanies: values?.firstReferenceForCompanies ?? null,
					secondReferenceForCompanies: values?.secondReferenceForCompanies ?? null,
					scumlCertificate: values?.scumlCertificate ?? null,
					nipcCertificate: values?.nipcCertificate ?? null,
					others: values?.others ?? null,
					letterOfAppointmentAsBankersForEnterprise:
						values?.letterOfAppointmentAsBankersForEnterprise ?? null,
					formOfApplicationForRegistrationOfBusinessName:
						values?.formOfApplicationForRegistrationOfBusinessName ?? null,
					legalSearchReport: values?.legalSearchReport ?? null,
					addressVerification: values?.addressVerification ?? null,
					deedOfAppointmentAsTrustee: values?.deedOfAppointmentAsTrustee ?? null,
					letterOfIndemnity: values?.letterOfIndemnity ?? null,
					letterOfReferenceFromTheMinistryOfExternalAffairs:
						values?.letterOfReferenceFromTheMinistryOfExternalAffairs ?? null,
					offcialDocumentFromHomeCountry: values?.offcialDocumentFromHomeCountry ?? null,
					letterOfCredenceOfTheAmbassadorFromTheHomeCountry:
						values?.letterOfCredenceOfTheAmbassadorFromTheHomeCountry ?? null,
					approvalFromTheMinistryOfEducation: values?.approvalFromTheMinistryOfEducation ?? null,
					recentUtilityBill: values?.recentUtilityBill ?? null,
					certificateOfComplianceWithNEPB: values?.certificateOfComplianceWithNEPB ?? null,
					residencePermitCardExpatriatesSignatories:
						values?.residencePermitCardExpatriatesSignatories ?? null,
					passportPhotographsOfAllSignatories: values?.passportPhotographsOfAllSignatories ?? null,
					completedAccountPackage: values?.completedAccountPackage ?? null,
					taxIdentificationNumber: values?.taxIdentificationNumber ?? null,
					validIdOfAllSignatories: values?.validIdOfAllSignatories ?? null,
					validIdOfDirectors: values?.validIdOfDirectors ?? null,
					bvnOfSignatories: values?.bvnOfSignatories ?? null,
					bvnOfDirectors: values?.bvnOfDirectors ?? null,
					kycForm: values?.kycForm ?? null,
				},
			};

		case AccountReactivationSteps.bankingServices:
			return {
				currentStep,
				progress: 80,
				bankingServices: {
					serviceRequests: values?.serviceRequests?.map((request: any) => request?.label) ?? [],
					businessRequests: values?.businessRequests?.map((request: any) => request?.label) ?? [],
					termsAndConditions: values?.termsAndConditions,
					emailIndemnity: values?.emailIndemnity,
					legalSearch: values?.legalSearch,
				},
			};
	}
};

export const isOustandingValue = (file: string) => {
	if (file) {
		file = file.trim();
		return file === "taxIdentificationNumber" || file === "BVNOfDirectors" || file === "validIdOfDirectors";
	}
};

export const checkUserValid = (director: DirectorDetails) => {
	if (director.bvn && director?.dob && director?.idNumber && director.idType.value) {
		return true;
	} else {
		return false;
	}
};

export const formatBusinessRegistartionSaveAndContinue = (values: any, currentStep: string) => {
	switch (currentStep) {
		case BusinessRegistrationSteps.accountDetails:
			return {
				currentStep,
				progress: 10,
				accountDetails: {
					typeOfCompany: values?.typeOfCompany,
					email: values?.email,
					referralCode: values?.referralCode ? values?.referralCode?.toString() : "",
					id: values?.id,
				},
			};
		case BusinessRegistrationSteps.identityVerification:
			return {
				currentStep,
				progress: 20,
				identityVerification: {
					bvn: values?.bvn,
					idType: values?.idType?.label,
					idNumber: values?.idNumber,
					dateOfBirth: values?.dateOfBirth,
					issuedDate: values?.issueDate ?? "",
					expiryDate: values?.expiryDate ?? "",
					name: values?.name,
					gender: values?.gender,
					id: values?.id,
				},
			};
		case BusinessRegistrationSteps.ownerInformation:
			return {
				currentStep,
				progress: 30,
				ownerInformation: {
					phoneNumber: values?.phoneNumber,
					countryCode: values?.countryCode?.label,
					email: values?.email,
					occupation: values?.occupation?.label,
					nationality: values?.nationality?.label,
					operatingAddress: values?.operatingAddress,
					state: values?.state?.label,
					lga: values?.lga?.label,
					signature: values?.signature,
					passport: values?.passport,
					validId: values?.validId,
					id: values?.id,
				},
			};
		case BusinessRegistrationSteps.businessInformation:
			return {
				currentStep,
				progress: 40,
				businessInformation: {
					businessDescription: values?.businessDescription,
					businessAddress: values?.businessAddress,
					businessSector: values?.businessSector?.label,
					businessCategoryAmount: values?.businessCategoryAmount,
					businessCategory: values?.businessCategory?.label,
					state: values?.state?.label,
					lga: values?.lga?.label,
					termsAndConditions: values?.termsAndConditions,
					id: values?.id,
				},
			};

		case BusinessRegistrationSteps.businessName:
			return {
				currentStep,
				progress: 50,
				businessName: {
					businessNameProposal1: values?.businessNameProposal1,
					isBusinessNameProposal1: values?.isBusinessNameProposal1,
					businessNameProposal2: values?.businessNameProposal2,
					isBusinessNameProposal2: values?.isBusinessNameProposal2,
					businessNameProposal3: values?.businessNameProposal3,
					isBusinessNameProposal3: values?.isBusinessNameProposal3,
					id: values?.id,
				},
			};

		case BusinessRegistrationSteps.shareholderDetails:
			return {
				currentStep,
				progress: 60,
				shareholderDetails: values?.map((s: any) => ({
					bvn: s?.bvn,
					shareholderType: s?.shareholderType?.label,
					idType: s?.idType?.label,
					dob: s?.dob,
					idNumber: s?.idNumber,
					countryCode: s?.countryCode?.label,
					phoneNumber: s?.phoneNumber,
					title: s?.title?.label,
					shareholderName: s?.shareholderName,
					email: s?.email,
					dateOfIncorporation: s?.dateOfIncorporation,
					occupation: s?.occupation?.label,
					nationality: s?.nationality?.label,
					gender: s?.gender,
					businessSector: s?.businessSector?.label,
					operatingAddress: s?.operatingAddress,
					country: s?.country?.label,
					state: s?.state?.label,
					lga: s?.lga?.label,
					numberOfShares: s?.numberOfShares,
					shareholderSignature: s?.shareholderSignature,
					shareholderIdCard: s?.shareholderIdCard,
					rcNumber: s?.rcNumber,
					tin: s?.tin,
				})),
			};

		case BusinessRegistrationSteps.presenterDetails:
			return {
				currentStep,
				progress: 60,
				presenterDetails: {
					hasPresenter: values?.hasPresenter?.label,
					presenters:
						values?.hasPresenter?.label === TruthyEnum.Yes
							? values?.presenters?.map((p: any) => ({
									bvn: p?.bvn,
									presenterType: p?.presenterType?.label,
									idType: p?.idType?.label,
									dob: p?.dob,
									idNumber: p?.idNumber,
									countryCode: p?.countryCode?.label,
									phoneNumber: p?.phoneNumber,
									title: p?.title?.label,
									presenterName: p?.presenterName,
									email: p?.email,
									dateOfIncorporation: p?.dateOfIncorporation,
									nationality: p?.nationality?.label,
									gender: p?.gender,
									businessSector: p?.businessSector?.label,
									operatingAddress: p?.operatingAddress,
									country: p?.country?.label,
									state: p?.state?.label,
									lga: p?.lga?.label,
									presenterSignature: p?.presenterSignature,
									presenterIdCard: p?.presenterIdCard,
									rcNumber: p?.rcNumber,
									tin: p?.tin,
							  }))
							: [],
				},
			};

		case BusinessRegistrationSteps.completed:
			return {
				currentStep,
				progress: 100,
				txReference: values?.txReference,
			};
	}
};

export const isLimitedLiabilityCompany = (company: BusinessCategory) => {
	return company === BusinessCategory.LimitedLiabilityCompany;
};

export const generateHeader = () => {
	const dateToUse = new Date();
	const UTCTimestamps = dateToUse.toISOString().replace("Z", "");
	const dateInToken = UTCTimestamps.replace("T", "")
		.replace(":", "")
		.replace(":", "")
		.substring(0, UTCTimestamps.length - 7);
	const shaOneEncrypt = CryptoJS.SHA512(dateInToken + config.client_id + config.xtoken_password);
	const apiHeader = {
		"x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
		Client_ID: config.client_id,
		"Ocp-Apim-Subscription-Key": config.subscription_key_value,
		UTCTimestamp: UTCTimestamps,
	};
	return apiHeader;
};

export const updateTokenData = (data: any) => {
	try {
		localStorage.setItem("token", data?.token);
		localStorage.setItem("refreshToken", data?.refreshToken);
	} catch (error) {
		console.log(error);
	}
};

const defaultKey = config.ENCRYPTION_KEY;
const iv = config.ENCRYPTION_IV;

const clientIdToKey = (clientId: any) => {
	let clientKey = clientId.toString();
	while (clientKey.length < 24) {
		clientKey = `0 + ${clientKey}`;
	}
	return clientKey;
};

const asciiToHex = (str: string) => {
	const arr1 = [];
	for (let n = 0, l = str?.toString().length; n < l; n += 1) {
		const hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	}
	return arr1.join("");
};

export const encrypt = (value: any, token = defaultKey) => {
	if (typeof value === "object") {
		value = JSON.stringify(value); // eslint-disable-line no-param-reassign
	}

	const key = CryptoJS.enc.Hex.parse(asciiToHex(clientIdToKey(token)));

	const initialVector = CryptoJS.enc.Hex.parse(asciiToHex(iv));

	const encrypted = CryptoJS.AES.encrypt(value, key, {
		iv: initialVector,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
		keySize: 192,
	});

	const transitMessage = encrypted.toString();
	return transitMessage;
};
