import { env } from '../.env'
const config = {
	"SERVER_URL": env.REACT_APP_SERVER_URL,
	"DOCUMENT_SERVER": env.REACT_APP_DOCUMENT_SERVER,
	"SAVE_CONTINUE_SERVER": env.REACT_APP_SAVE_CONTINUE_SERVER,
	"VERIFYME_PUBLIC_KEY": env.REACT_APP_VERIFYME_PUBLIC_KEY,
	"VERIFYME_PRODUCT_CODE": env.REACT_APP_VERIFYME_PRODUCT_CODE,
	"ADMIN_URL": env.REACT_APP_ADMIN_URL,
	"REFERENCE_URL": env.REACT_APP_REFERENCE_URL,
	"PaymentGatewayPublicKey": env.REACT_APP_PaymentGatewayPublicKey,
	"PaymentGatewayUrl": env.REACT_APP_PaymentGatewayUrl,
	"PaymentGatewayMerchantCode": env.REACT_APP_PaymentGatewayMerchantCode,
	"isProduction": env.REACT_APP_IS_PRODUCTION === "true" ? true : false,
	"EnterpriseBusinessRegAmount": env.REACT_APP_BUSINESS_REGISTRATION_Enterprise,
	"LimitedLiabilityCompanyBusinessRegAmount": env.REACT_APP_BUSINESS_REGISTRATION_LimitedLiabilityCompany,
	"IS_CLOUD": env.REACT_APP_IS_CLOUD === "true" ? true : false,
	"subscription_key_name": env.REACT_APP_subscription_key_name,
	"subscription_key_value": env.REACT_APP_subscription_key_value,
	"x_correlation_id": env.REACT_APP_x_correlation_id,
	"xtoken_password": env.REACT_APP_xtoken_password,
	"client_id": env.REACT_APP_CLIENT_ID,
	"product_id": env.REACT_APP_PRODUCT_ID,
	numberOfShares: Number(env.REACT_APP_NUMBER_OF_SHARES),
	isStaff: env.REACT_APP_IS_STAFF === "true",
	"ENCRYPTION_KEY": env.REACT_APP_ENCRYPTION_KEY,
	"ENCRYPTION_IV": env.REACT_APP_ENCRYPTION_IV,
	"REFERENCE_SERVER": env.REACT_APP_REFERENCE_SERVER
}

export default config
