/* eslint-disable no-throw-literal */

import axios from "axios";
import config from "./config";
import store from "../store";
import { uiStartLoading, uiStopLoading } from "../store/actions/loading";
import { generateHeader } from "../utils/helpers";
import { updateTokenData } from "../utils/helpers";
const Axios = axios.create({
	baseURL: config.SERVER_URL,
	headers: {
		'X-Frame-Options': 'DENY',
		'X-Content-Type-Options': 'nosniff',
		"Cross-Origin-Opener-Policy": "same-origin",
		"Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
	},
});

const { dispatch } = store;
Axios.interceptors.request.use(
	(axiosConfig) => {
		if (!navigator.onLine) {
			throw new Error("Please check your Internet Connection")
		}
		const token = localStorage.getItem("token")
		if (config.isStaff && token && (axiosConfig?.url !== "staff-login" && axiosConfig?.url !== "OpenXframe")) {
			axiosConfig.headers["Authorization"] = `Bearer ${token}`;
		}
		if (config.IS_CLOUD) {
			const headers = generateHeader();
			axiosConfig.headers['x-token'] = headers['x-token'];
			axiosConfig.headers['Ocp-Apim-Subscription-Key'] = headers['Ocp-Apim-Subscription-Key'];
			axiosConfig.headers['Ocp-Apim-Trace'] = true;
			axiosConfig.headers.UTCTimestamp = headers.UTCTimestamp;
			axiosConfig.headers.Client_ID = headers.Client_ID;
		}
		dispatch(uiStartLoading());
		return axiosConfig;
	},
	(error) => {
		dispatch(uiStopLoading());
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(response) => {
		if (response.status === 200 && response.data?.code !== "00") {
			dispatch(uiStopLoading());
			return Promise.reject(response?.data?.description);

		}
		dispatch(uiStopLoading());
		return response;
	},
	async (err) => {
		const originalConfig = err.config;
		if (config.isStaff && originalConfig?.url !== "staff-login" && originalConfig?.url !== "OpenXframe" && err?.response) {
			// Access Token was expired
			const refreshToken = localStorage.getItem("refreshToken");
			if (refreshToken && err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;
				try {
					const res = await axios.post(`${config.SERVER_URL}get-refresh-token`, {
						refreshToken
					});
					updateTokenData(res.data?.data)
					return Axios(originalConfig);
				} catch (_error) {
					localStorage.clear()
					window.location.reload()
					return Promise.reject(_error);
				}
			}
		}
		dispatch(uiStopLoading());
		return Promise.reject(err);
	}
);
export default Axios;
