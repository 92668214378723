export const makeAPIAction = (actionName: string) => {
  return {
    REQUEST: `@@API_${actionName}_REQUEST`,
    SUCCESS: `@@API_${actionName}_SUCCESS`,
    FAILURE: `@@API_${actionName}_FAILURE`,
  };
};

export const UI_START_LOADING = "UI_START_LOADING";
export const UI_STOP_LOADING = "UI_STOP_LOADING";
export const SET_BUSINESS_REGISTRATION_DATA = "SET_BUSINESS_REGISTRATION_DATA";