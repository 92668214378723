import store from "..";
import SaveAndContinueAxios from "../../config/SaveAndContinueAxios";
import { AccountReactivationSteps, AdditionalAccountSteps, BusinessRegistrationSteps, NewAccountSteps, RoutesEnum } from "../../utils/enums";
import { getLocalData, removeLocalData, saveDataLocally } from "../../utils/helpers";
import * as actionTypes from "./actionTypes"
export const newAccountSaveAndContinueAction = async (payload: any, previousData: any = {}, exit = false) => {
  try {
    const data = getLocalData(NewAccountSteps.companyInfo)
    let response = await SaveAndContinueAxios.post(`NewToBank/save`, { ...previousData, ...payload, email: data?.companyEmail ?? payload?.companyInfo?.companyEmail });
    if (response.status === 200 && response.data?.code === "00") {
      if (payload?.currentStep !== NewAccountSteps.completed) {
        saveDataLocally("currentStep", payload?.currentStep);
        if (exit) removeLocalData()
      }
      return response.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNewAccountSavedData = async (email: string, currentStep?: string) => {
  try {
    let response = await SaveAndContinueAxios.post(`NewToBank/get`, { email, currentStep });
    if (response.status === 200 && response.data?.code === "00") {
      return response.data?.data;
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export const additionalAccountSaveAndContinueAction = async (payload: any, previousData: any = {}) => {
  try {
    let response = await SaveAndContinueAxios.post(`additional-sme-accounts/save-additional-sme-account`, { ...previousData, ...payload });
    if (response.status === 200 && response.data?.code === "00") {
      if (payload?.currentStep !== AdditionalAccountSteps.completed) {
        saveDataLocally("currentStep", payload?.currentStep, "additionalAccount");
      }
      return response.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAdditionalAccountSavedData = async (accountNumber: string) => {
  try {
    let response = await SaveAndContinueAxios.post(`additional-sme-accounts/get-additional-sme-account`, { accountNumber });
    if (response.status === 200 && response.data?.code === "00") {
      return response.data?.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}


export const accountReactivationSaveAndContinueAction = async (payload: any, previousData: any = {}) => {
  try {
    let response = await SaveAndContinueAxios.post(`reactivate-sme-accounts/save-reactivate-sme-account`, { ...previousData, ...payload });
    if (response.status === 200 && response.data?.code === "00") {
      if (payload?.currentStep === AccountReactivationSteps.completed) {
        removeLocalData("accountReactivation")
      } else {
        saveDataLocally("currentStep", payload?.currentStep, "accountReactivation");
      }
      return response.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountReactivationSavedData = async (accountNumber: string) => {
  try {
    let response = await SaveAndContinueAxios.post(`reactivate-sme-accounts/get-reactivate-sme-account`, { accountNumber });
    if (response.status === 200 && response.data?.code === "00") {
      return response.data?.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const businessRegistrationSaveAndContinueAction = async (payload: any, previousData: any = {}) => {
  try {
    const data = getLocalData(BusinessRegistrationSteps.accountDetails, RoutesEnum.businessRegistration);
    let response = await SaveAndContinueAxios.post(`register-business/save`, { ...previousData, ...payload, email: data?.email ?? payload?.accountDetails?.email, txReference: payload?.txReference ?? "" });
    if (response.status === 200 && response.data?.code === "00") {
      if (payload?.currentStep === BusinessRegistrationSteps.completed) {
        removeLocalData(RoutesEnum.businessRegistration)
      } else {
        saveDataLocally("currentStep", payload?.currentStep, RoutesEnum.businessRegistration);
      }
      return response.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};


export const getBusinessRegistrationSavedData = async (email: string, currentStep: string| null) => {
  const { dispatch } = store;
  try {
    let response = await SaveAndContinueAxios.get(`register-business/get`, { params: {email, currentStep: currentStep ?? ""}  });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.SET_BUSINESS_REGISTRATION_DATA,
        data: response?.data
      })
      return response?.data
    }
  } catch (error) {
    return Promise.reject(error);
  }
}