import axios from "axios";
import Axios from "../../config/Axios";
import store from "..";
import { uiStartLoading, uiStopLoading } from "./loading";
import { states } from "../../utils/constants";
import { findArrayItem } from "../../utils/helpers";
import moment from "moment";
import ReferenceAxios from "../../config/ReferenceAxios";
import AdminAxios from "../../config/AdminAxios";

const { dispatch } = store;

export const getProducts = async () => {
	try {
		let response = await Axios.get(`products`);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getBusinessRequests = async () => {
	try {
		let response = await Axios.get(`get-business-request-types`);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendBusinessRequests = async (payload: any) => {
	try {
		let response = await Axios.post(`submit-business-request`, payload);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const verifyId = async (payload: any) => {
	try {
		let response = await Axios.post(`id-verification`, {
			firstName: "string",
			lastName: "string",
			phone: "string",
			dob: "string",
			email: "string",
			gender: "string",
			selfie: "string",
			idValue: "string",
			...payload,
		});
		if (response.status === 200 && response.data?.code === "00") {
			return response.data.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const checkRcOnFinacle = async (rcNumber: string) => {
	try {
		let response = await Axios.post(`verify-rcNumber`, { rcNumber });
		if (response.status === 200 && response.data?.code === "00") {
			return response.data.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const verifyBVN = async (bvn: any) => {
	try {
		let response = await Axios.post(`validate-bvn`, { bvn });
		if (response.status === 200 && response.data?.code === "00") {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createCoperateAccount = async (payload: any) => {
	try {
		let response = await Axios.post(`create-corporate-account`, payload);
		if (response.status === 200 && response.data?.code === "00") {
			return response.data?.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createCoperateMembers = async (payloads: any[], accountNumber: string) => {
	try {
		let requests: any[] = [];
		payloads.forEach((member: any) => {
			requests = [
				...requests,
				Axios.post(`create-corporate-member`, { ...member, corporate_Account: accountNumber }),
			];
		});
		return await Promise.all(requests);
	} catch (error) {
		return Promise.reject(error);
	}
};

const formatMandateDescription = (mandate: any, signatory: any) => {
	const confirmationAmount = mandate?.confirmationLimit ? mandate?.confirmationLimit : "Nil";
	if (mandate?.other) {
		return `${signatory?.lowerLimit}-${signatory?.upperLimit}. Confirm Amount- ${confirmationAmount}`;
	} else if (mandate?.authorizationRule === "Sole Signatory") {
		return `No Limit. Confirm Amount- ${confirmationAmount}`;
	}
	return `${signatory?.lowerLimit}-${signatory?.upperLimit}. Confirm Amount- ${confirmationAmount}`;
};

export const addMandate = async (payload: any, accountNumber: string) => {
	try {
		let requests: any[] = [];
		payload?.signatories.forEach((signatory: any) => {
			requests = [
				...requests,
				Axios.post(`add-mandate`, {
					accountNumber,
					image: signatory?.image,
					signinst: payload?.authorizationRule,
					name: signatory?.name,
					limit: formatMandateDescription(payload, signatory),
				}),
			];
		});
		const res = await Promise.all(requests);
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getVerifiedUserLive = async (reference: string, pkey: string) => {
	dispatch(uiStartLoading());
	try {
		let response = await axios.get(`https://vapi.verifyme.ng/v1/verifications/liveness/${reference}`, {
			headers: {
				authorization: `Bearer ${pkey}`,
			},
		});
		if (response.status === 200 && response?.data?.status === "success") {
			dispatch(uiStopLoading());
			return response.data?.data?.identityDetails?.photo;
		}
		dispatch(uiStopLoading());
	} catch (error) {
		dispatch(uiStopLoading());
		return Promise.reject(error);
	}
};

export const verifyBusinessAddress = async (company: any, director: DirectorDetails) => {
	const state = findArrayItem(states, "stateCode", company?.state);
	const lga = findArrayItem(state?.lgas, "lgaCode", company?.city?.trim(), "lga");
	try {
		let response = await Axios.post(`business-address-verification`, {
			businessName: company?.corporateName,
			businessType: "Formal",
			rcNumber: company?.rcNumber,
			street: company?.streetName,
			lgaName: lga,
			stateName: state?.state,
			landmark: company?.corporateName,
			city: lga,
			canContactPoc: false,
			applicant: {
				idType: "bvn",
				idNumber: director.bvn,
				firstname: director.firstName,
				lastname: director.lastName,
				phone: director.phoneNumber,
				email: director.email,
				dob: moment(new Date(director.dob)).format("YYYY-MM-DD"),
			},
		});
		if (response.status === 200 && response.data?.code === "00") {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createAdditionalAccount = async (payload: any) => {
	try {
		let response = await Axios.post(`additional-account`, payload);
		if (response.status === 200 && response.data?.code === "00") {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getServiceRequests = async () => {
	try {
		let response = await Axios.get(`get-service-request-types-for-dropdown`);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const formatSendServiceRequest = (accountDetails: any) => {
	return {
		accountId: accountDetails?.accountId,
		accountNumber: accountDetails?.accountNumber,
		accountName: accountDetails?.accountName,
		cifId: accountDetails?.cifId,
		brokerCode: "1072",
		phoneNumber: accountDetails?.phoneNumber,
		email: accountDetails?.email,
	};
};

export const sendServiceRequests = async (payload: any) => {
	try {
		let response = await Axios.post(`integrify-service-request`, payload);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getAccountStatus = async (accountNumber: string) => {
	try {
		let response = await Axios.post(`account-status-bvn`, {
			accountNumber,
		});
		return response.data?.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const reactivateAccount = async (accountNumber: string) => {
	try {
		let response = await Axios.post(`reactivate-accounts`, {
			accountNumber,
		});
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const verifyRefreeAccount = async (AccountNumber: string) => {
	try {
		let response = await ReferenceAxios.get("validate-account", { params: { AccountNumber } });
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendFcmbReference = async (payload: { refereeAccount: string; ntbCustomerAccount: string }) => {
	try {
		let response = await ReferenceAxios.post("charges-collection-opt", payload);
		if (response.status === 200) {
			return response.data?.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const sendOtherBankReference = async (payload: {
	ntbAccountNumber: string;
	nameOfReferee: string;
	refereeEmail: string;
	ntbCustomerCompany: string;
	accountCategory: string;
}) => {
	try {
		let response = await AdminAxios.post("customers/send-email-for-reference", payload);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};
export const updateOtherBankReference = async (payload: {
	requestId: string;
	ntbAccountNumber: string;
	referenceLetter: string;
	numberOfYears: string;
	refereeBank: string;
	refereeBankAddress: string;
	refereeCompanyName: string;
	refereeBankAccount: string;
	refereeSignature: string;
	ntbCustomerCompany: string;
	nameOfReferee: string;
	refereeEmail: string;
}) => {
	try {
		let response = await AdminAxios.post("customers/update-NTB-customer-reference", payload);
		if (response.status === 200) {
			return response.data;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};
