
import Axios from "../../config/Axios";
import ReferenceAxios from "../../config/ReferenceAxios";
import { notifyError } from "../../utils/helpers";

export const requestForInternalReference = async (payload: any) => {
  try {
    let response = await ReferenceAxios.post(`InternalReference/RequestLetter`, payload);
    if (response.status === 200) {
      return response.data?.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmReferenceTicket = async (payload: any) => {
  try {
    let response = await ReferenceAxios.post(`InternalReference/ConfirmLetter`, payload);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    notifyError(error?.message)

  }
};

export const validateReferenceAccountNumber = async (payload: any) => {
  try {
    let response = await Axios.post(`validate-reference-account`, payload);
    if (response.status === 200) {
      return response.data?.data?.accountDetails;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};