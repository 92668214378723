import { combineReducers } from "@reduxjs/toolkit";
import uiReducer from "./loading";
import otpReducer from "./otpSlice";
import referenceDetailsReducer from "./digitalReferee";
import saveAndContinueReducer from "./saveAndContinue";

const reducers = combineReducers({
	loading: uiReducer,
	otp: otpReducer,
	referenceDetails: referenceDetailsReducer,
	saveAndContinue: saveAndContinueReducer,
});

export default reducers;
