import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
	accountNumber: string;
};

const initialState: InitialState = {
	accountNumber: "",
};

export const otpSlice = createSlice({
	name: "otp",
	initialState: initialState,
	reducers: {
		updateAccountNumber: (state, action: PayloadAction<string>) => {
			state.accountNumber = action.payload;
		},
	},
});

export default otpSlice.reducer;
export const { updateAccountNumber } = otpSlice.actions;
