import Axios from "../../config/Axios";
import { notifyError } from "../../utils/helpers";

export const assignRegisterMyBusinessToConsultant = async (payload: any) => {
  try {
    let response = await Axios.post(`submit-business-registration`, payload);
    if (response.status === 200) {
      return response.data?.data;
    }
  } catch (error: any) {
    notifyError(error?.message)
    return Promise.reject(error);
  }
};