import React, { HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props extends HTMLAttributes<HTMLElement> {
	classes?: string;
	title: string;
	type?: "button" | "submit";
	bg?: string;
	disabled?: boolean;
	paddingY?: string;
	containerClass?: string;
	outlined?: boolean
}

const Button = ({
	classes,
	title,
	type = "submit",
	bg = "bg-gradient-to-r from-[#63098C] to-[#A11D8F] hover:from-[#A11D8F] hover:to-[#63098C]",
	disabled,
	paddingY = "py-[16px]",
	containerClass = "w-full",
	outlined,
	...others
}: Props) => {
	const {
		loading: { isLoading },
	} = useSelector((store: RootState) => store);
	bg = outlined ? "border border-secondary text-gray-400 text-center hover:bg-primary-100 hover:text-primary-700 hover:border-primary-100" : bg
	return (
		<section className={containerClass}>
			<button
				type={type}
				className={`${disabled ? "opacity-50" : null
					} w-full block text-white text-center text-[16px] py-[12px] px-[3%] box-border rounded-md ${bg} ${paddingY} ${classes}`}
				{...others}
				disabled={disabled || isLoading}
			>
				{title}
			</button>
		</section>
	);
};

export default Button;
