import React, { useState, useEffect } from 'react'
import Button from '../formElements/Button'
import { getLocalData, saveDataLocally } from '../../utils/helpers'

export default function Privacy() {
  const [privacy, setPrivacy] = useState(false)
  const accept = () => {
    setPrivacy(true)
    saveDataLocally("accept", true, 'privacy')
  }
  
  useEffect(()=>{
    const privacy = getLocalData("accept", "privacy")
    if(privacy){
      setPrivacy(privacy)
    }
  }, [])
  return (
    <section>{
      !privacy &&
      <div className="fixed inset-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-10">
        <div className='absolute bottom-0 left-0 right-0 '>
          <div className='max-w-[650px] w-full m-auto bg-white p-3 border-t-4 border-primary'>
            <h1 className='font-semibold mb-2'>Privacy Policy</h1>
            <p className='px-2 text-sm'>This website uses cookies to ensure you get the best experience. <a href='https://www.fcmb.com/privacy-policy' target='_blank' rel="noreferrer" className='text-primary underline font-bold'>Read our Privacy Policy here.</a> </p>
            <section className='flex justify-end mt-3'>
              <Button onClick={accept} title='Accept' containerClass='w-[20%]' classes='py-1' />
            </section>

          </div>
        </div>
      </div>
    }</section>


  )
}
