import Axios from "../../config/Axios";
import { notifySuccess } from "../../utils/helpers";
import DocumentAxios from "../../config/DocumentsAxios";

// validate otp
export const getAccountDetails = async (payload: any) => {
	try {
		let response = await Axios.post(`account-details`, payload);
		return response.data?.data[0];
	} catch (error) {
		return Promise.reject(error);
	}
};



export const updateDocument = async (payload: {}) => {
	try {
		let response = await DocumentAxios.post(`UpdateSMEDocument`, payload);
		notifySuccess(response.data?.message);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const uploadDocument = async (payload: {}) => {
	try {
		let response = await DocumentAxios.post(`UploadSMEDocument`, payload);
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getOutStandingDocs = async (params: any) => {
	try {
		let response = await DocumentAxios.get(`GetSMEOutstandingDocuments`, {
			params,
		});
		return response.data?.data?.outstandingDocuments;
	} catch (error) {
		return Promise.reject(error);
	}
};
