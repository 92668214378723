import Axios from "../../config/Axios";

export * from './accounts'
export * from './otp'
export * from './documentUpload'
export * from './saveAndContinue'
export * from './businessRegistration'
export * from './auth'
export * from './references'
export const clickjackingFix = async () => {
	try {
		let response = await Axios.get(`OpenXframe`);
		if (response.status === 200) {
			return response.data
		}
	} catch (error) {
	}
};
