
import Axios from "../../config/Axios";
import { notifyError } from "../../utils/helpers";

export const login = async (payload: any) => {
  try {
    let response = await Axios.post('staff-login', {
      encryptedString: payload
    });
    if (response.status === 200 && response.data?.code === "00") {
      localStorage.setItem("token", response.data?.data?.accessToken)
      localStorage.setItem("refreshToken", response.data?.data?.refreshToken)
      return true
    }
  } catch (error: any) {
    notifyError(error?.message)
  }
};

export const logout = async () => {
  try {
    let response = await Axios.get(`staff-logout`);
    if (response.status === 200 && response.data?.code === "00") {
      localStorage.removeItem("token")
      localStorage.removeItem("refreshToken")
      return true
    }
  } catch (error: any) {
    notifyError(error?.message)
  }
};