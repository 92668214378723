import * as actionTypes from "../actions/actionTypes"


const initalState = { businessRegistration: {}, accountOpening: {}, accountReactivation: {}, additionalAccount: {} }

const saveAndContinueReducer = (state = initalState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_BUSINESS_REGISTRATION_DATA:
      return {
        ...state, businessRegistration: action.data
      }
    default:
      return state

  }
}

export default saveAndContinueReducer