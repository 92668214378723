import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
	referenceDetails: {};
	uploadedSignature: any;
	referenceSubmitted: boolean;
};

const initialState: InitialState = {
	referenceDetails: {},
	uploadedSignature: null,
	referenceSubmitted: false,
};

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
	name: "digitalReference",
	initialState,
	reducers: {
		setReferenceDetails: (state, action: PayloadAction<any>) => {
			state = {
				...state,
				referenceDetails: action.payload,
			};
			return state;
		},
		setSignature: (state, action: PayloadAction<any>) => {
			state = {
				...state,
				uploadedSignature: action.payload,
			};
			return state;
		},
		setSubmitted: (state, action: PayloadAction<boolean>) => {
			state = {
				...state,
				referenceSubmitted: action.payload,
			};
			return state;
		},
	},
});

export default authSlice.reducer;
export const { setReferenceDetails, setSignature, setSubmitted } = authSlice.actions;
// export const userAuthSelector = (state:any) => state.useAuth;
