import { Dispatch } from "redux"
import * as actionTypes from "./actionTypes"

export const uiStartLoading = () => {
  return async (dispatch: DispatchType) => {
    dispatch({
      type: actionTypes.UI_START_LOADING
    })
  }
}

export const uiStopLoading = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: actionTypes.UI_STOP_LOADING
    })
  }
}